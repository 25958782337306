export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAILURE = "USER_UPDATE_FAILURE";
export const USER_FETCH_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_FETCH_FAILURE = "USER_UPDATE_FAILURE";

export const CART_FETCH_SUCCESS = "CART_FETCH_SUCCESS";
export const CART_FETCH_FAILURE = "CART_FETCH_FAILURE";

export const ADD_EVENT_START = "ADD_EVENT_START";
export const ADD_EVENT_SUCCESS = "ADD_EVENTS_SUCCESS";
export const ADD_EVENT_FAIL = "ADD_EVENTS_FAIL";

export const FETCH_EVENTS_START = "FETCH_EVENTS_START";
export const FETCH_EVENTS_SUCCESS = "FETCH_EVENTS_SUCCESS";
export const FETCH_EVENTS_FAIL = "FETCH_EVENTS_FAIL";

export const FETCH_EVENTS_CATEGORIES_START = "FETCH_EVENTS_CATEGORIES_START";
export const FETCH_EVENTS_CATEGORIES_SUCCESS =
  "FETCH_EVENTS_CATEGORIES_SUCCESS";
export const FETCH_EVENTS_CATEGORIES_FAIL = "FETCH_EVENTS_CATEGORIES_FAIL";

export const ADD_WORKSHOP_START = "ADD_WORKSHOP_START";
export const ADD_WORKSHOP_SUCCESS = "ADD_WORKSHOPS_SUCCESS";
export const ADD_WORKSHOP_FAIL = "ADD_WORKSHOPS_FAIL";

export const FETCH_WORKSHOP_START = "FETCH_WORKSHOP_START";
export const FETCH_WORKSHOP_SUCCESS = "FETCH_WORKSHOP_SUCCESS";
export const FETCH_WORKSHOP_FAIL = "FETCH_WORKSHOP_FAIL";

export const FETCH_WORKSHOP_CATEGORIES_START =
  "FETCH_WORKSHOP_CATEGORIES_START";
export const FETCH_WORKSHOP_CATEGORIES_SUCCESS =
  "FETCH_WORKSHOP_CATEGORIES_SUCCESS";
export const FETCH_WORKSHOP_CATEGORIES_FAIL = "FETCH_WORKSHOP_CATEGORIES_FAIL";

export const ADD_INFORMAL_EVENTS_START = "ADD_INFORMAL_EVENTS_START";
export const ADD_INFORMAL_EVENTS_SUCCESS = "ADD_INFORMAL_EVENTS_SUCCESS";
export const ADD_INFORMAL_EVENTS_FAIL = "ADD_INFORMAL_EVENTS_FAIL";

export const FETCH_INFORMAL_EVENTS_START = "FETCH_INFORMAL_EVENTS_START";
export const FETCH_INFORMAL_EVENTS_SUCCESS = "FETCH_INFORMAL_EVENTS_SUCCESS";
export const FETCH_INFORMAL_EVENTS_FAIL = "FETCH_INFORMAL_EVENTS_FAIL";

export const FETCH_INFORMAL_EVENTS_CATEGORIES_START =
  "FETCH_INFORMAL_EVENTS_CATEGORIES_START";
export const FETCH_INFORMAL_EVENTS_CATEGORIES_SUCCESS =
  "FETCH_INFORMAL_EVENTS_CATEGORIES_SUCCESS";
export const FETCH_INFORMAL_EVENTS_CATEGORIES_FAIL =
  "FETCH_INFORMAL_EVENTS_CATEGORIES_FAIL";

export const ADD_SPEAKER_START = "ADD_SPEAKER_START";
export const ADD_SPEAKER_SUCCESS = "ADD_SPEAKERS_SUCCESS";
export const ADD_SPEAKER_FAIL = "ADD_SPEAKERS_FAIL";

export const FETCH_SPEAKER_START = "FETCH_SPEAKER_START";
export const FETCH_SPEAKER_SUCCESS = "FETCH_SPEAKER_SUCCESS";
export const FETCH_SPEAKER_FAIL = "FETCH_SPEAKER_FAIL";

export const FETCH_SPEAKER_CATEGORIES_START = "FETCH_SPEAKER_CATEGORIES_START";
export const FETCH_SPEAKER_CATEGORIES_SUCCESS =
  "FETCH_SPEAKER_CATEGORIES_SUCCESS";
export const FETCH_SPEAKER_CATEGORIES_FAIL = "FETCH_SPEAKER_CATEGORIES_FAIL";

export const ADD_CONNEXION_START = "ADD_CONNEXION_START";
export const ADD_CONNEXION_SUCCESS = "ADD_CONNEXIONS_SUCCESS";
export const ADD_CONNEXION_FAIL = "ADD_CONNEXIONS_FAIL";

export const FETCH_CONNEXION_START = "FETCH_CONNEXION_START";
export const FETCH_CONNEXION_SUCCESS = "FETCH_CONNEXION_SUCCESS";
export const FETCH_CONNEXION_FAIL = "FETCH_CONNEXION_FAIL";

export const FETCH_CONNEXION_CATEGORIES_START =
  "FETCH_CONNEXION_CATEGORIES_START";
export const FETCH_CONNEXION_CATEGORIES_SUCCESS =
  "FETCH_CONNEXION_CATEGORIES_SUCCESS";
export const FETCH_CONNEXION_CATEGORIES_FAIL =
  "FETCH_CONNEXION_CATEGORIES_FAIL";
export const ADD_STRATEGIZER_START = "ADD_STRATEGIZER_START";
export const ADD_STRATEGIZER_SUCCESS = "ADD_STRATEGIZER_SUCCESS";
export const ADD_STRATEGIZER_FAIL = "ADD_STRATEGIZER_FAIL";
export const FETCH_STRATEGIZER_START = "FETCH_STRATEGIZER_START";
export const FETCH_STRATEGIZER_SUCCESS = "FETCH_STRATEGIZER_SUCCESS";
export const FETCH_STRATEGIZER_FAIL = "FETCH_STRATEGIZER_FAIL";

export const FETCH_STRATEGIZER_CATEGORIES_START =
  "FETCH_STRATEGIZER_CATEGORIES_START";
export const FETCH_STRATEGIZER_CATEGORIES_SUCCESS =
  "FETCH_STRATEGIZER_CATEGORIES_SUCCESS";
export const FETCH_STRATEGIZER_CATEGORIES_FAIL =
  "FETCH_STRATEGIZER_CATEGORIES_FAIL";

export const ADD_CONTACT_PERSON_START = "ADD_CONTACT_PERSON_START";
export const ADD_CONTACT_PERSON_SUCCESS = "ADD_CONTACT_PERSON_SUCCESS";
export const ADD_CONTACT_PERSON_FAIL = "ADD_CONTACT_PERSON_FAIL";
export const FETCH_CONTACT_PERSON_START = "FETCH_CONTACT_PERSON_START";
export const FETCH_CONTACT_PERSON_SUCCESS = "FETCH_CONTACT_PERSON_SUCCESS";
export const FETCH_CONTACT_PERSON_FAIL = "FETCH_CONTACT_PERSON_FAIL";

export const ADD_SPONSER_START = "ADD_SPONSER_START";
export const ADD_SPONSER_SUCCESS = "ADD_SPONSER_SUCCESS";
export const ADD_SPONSER_FAIL = "ADD_SPONSER_FAIL";
export const FETCH_SPONSER_START = "FETCH_SPONSER_START";
export const FETCH_SPONSER_SUCCESS = "FETCH_SPONSER_SUCCESS";
export const FETCH_SPONSER_FAIL = "FETCH_SPONSER_FAIL";

export const ADD_CERTIFICATE_START = "ADD_CERTIFICATE_START";
export const ADD_CERTIFICATE_SUCCESS = "ADD_CERTIFICATE_SUCCESS";
export const ADD_CERTIFICATE_FAIL = "ADD_CERTIFICATE_FAIL";
export const FETCH_CERTIFICATE_START = "FETCH_CERTIFICATE_START";
export const FETCH_CERTIFICATE_SUCCESS = "FETCH_CERTIFICATE_SUCCESS";
export const FETCH_CERTIFICATE_FAIL = "FETCH_CERTIFICATE_FAIL";

export const ADD_MEMBER_START = "ADD_MEMBER_START";
export const ADD_MEMBER_SUCCESS = "ADD_MEMBER_SUCCESS";
export const ADD_MEMBER_FAIL = "ADD_MEMBER_FAIL";
export const FETCH_MEMBER_START = "FETCH_MEMBER_START";
export const FETCH_MEMBER_SUCCESS = "FETCH_MEMBER_SUCCESS";
export const FETCH_MEMBER_FAIL = "FETCH_MEMBER_FAIL";

export const FETCH_SPONSER_CATEGORIES_START = "FETCH_SPONSER_CATEGORIES_START";
export const FETCH_SPONSER_CATEGORIES_SUCCESS =
  "FETCH_SPONSER_CATEGORIES_SUCCESS";
export const FETCH_SPONSER_CATEGORIES_FAIL = "FETCH_SPONSER_CATEGORIES_FAIL";

export const SEND_MESSAGE = "SEND_MESSAGE";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";

export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";

export const FETCH_REGISTRATIONS_START = "FETCH_REGISTRATIONS_START";
export const FETCH_REGISTRATIONS_SUCCESS = "FETCH_REGISTRATIONS_SUCCESS";
export const FETCH_REGISTRATIONS_FAIL = "FETCH_REGISTRATIONS_FAIL";

export const ADD_COUPON_START = "ADD_COUPON_START";
export const ADD_COUPON_SUCCESS = "ADD_COUPON_SUCCESS";
export const ADD_COUPON_FAIL = "ADD_COUPON_FAIL";

export const LOADING_START = "LOADING_START";
export const LOADING_STOP = "LOADING_STOP";
