export const initalValues = {
  name: {
    value: "",
    error: false,
    errorMessage: "",
    touched: false,
  },
  is_active: {
    value: 0,
    error: false,
    errorMessage: "",
    touched: false,
  },
};
