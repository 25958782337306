export const initalValues = {
  discount: {
    value: "",
    error: false,
    errorMessage: "",
    required: true,
    touched: false,
  },
  volume: {
    value: 0,
    error: false,
    errorMessage: "",
    required: true,
    touched: false,
  },
};
