import moment from "moment";

export const eventTypes = ["Flagship", "International", "Conclave", "On-Site"];
export const initalValues = {
  image_upload: {
    value: null,
    error: false,
    errorMessage: "",
    touched: false,
  },
  title: {
    value: "Event Title 5",
    error: false,
    errorMessage: "",
    required: true,
    touched: false,
  },
  slug: {
    value: "",
    error: false,
    errorMessage: "",
    required: true,
    touched: false,
  },
  type: {
    value: eventTypes[0],
    error: false,
    errorMessage: "",
    required: true,
    touched: false,
  },
  new_type: {
    value: "",
    error: false,
    errorMessage: "",
    touched: false,
  },
  image_id: {
    value: "",
    error: false,
    errorMessage: "",
    touched: false,
  },
  short_description: {
    value: "",
    error: false,
    errorMessage: "",
    touched: false,
  },
  full_description: {
    value: "",
    error: false,
    errorMessage: "",
    touched: false,
  },
  remark_one: {
    value: "",
    error: false,
    errorMessage: "",
    touched: false,
  },
  remark_two: {
    value: "",
    error: false,
    errorMessage: "",
    touched: false,
  },
  event_time: {
    value: moment(),
    error: false,
    errorMessage: "",
    touched: false,
  },
  event_date: {
    value: moment(),
    error: false,
    errorMessage: "",
    touched: false,
  },
  event_end_date: {
    value: moment(),
    error: false,
    errorMessage: "",
    touched: false,
  },
  event_end_time: {
    value: moment(),
    error: false,
    errorMessage: "",
    touched: false,
  },
  rules: {
    value: "",
    error: false,
    errorMessage: "",
    touched: false,
  },
  prizes: {
    value: "",
    error: false,
    errorMessage: "",
    touched: false,
  },
  event_format: {
    value: "",
    error: false,
    errorMessage: "",
    touched: false,
  },
  contact: {
    value: "",
    error: false,
    errorMessage: "",
    touched: false,
  },
  view_order: {
    value: "",
    error: false,
    errorMessage: "",
    touched: false,
  },
  register: {
    value: "",
    error: false,
    errorMessage: "",
    touched: false,
  },
  organising_club: {
    value: "",
    error: false,
    errorMessage: "",
    touched: false,
  },
  team_size: {
    value: "",
    error: false,
    errorMessage: "",
    touched: false,
  },
  event_duration: {
    value: "",
    error: false,
    errorMessage: "",
    touched: false,
  },
  venue: {
    value: "",
    error: false,
    errorMessage: "",
    touched: false,
  },
  is_active: {
    value: "",
    error: false,
    errorMessage: "",
    touched: false,
  },
  show_in_home_page: {
    value: 0,
    error: false,
    errorMessage: "",
    touched: false,
  },
  visible: {
    value: 0,
    error: false,
    errorMessage: "",
    touched: false,
  },
  event_timeline: {
    value: "",
    error: false,
    errorMessage: "",
    touched: false,
  },
  price: {
    value: 0,
    error: false,
    errorMessage: "",
    touched: false,
  },
};
