import React from "react";
import Marquee from "react-fast-marquee";
import "./SponsorSlider.css";

const SponsorSlider = (props) => {
  let images = props.images ? props.images : [];
  return(
    <Marquee speed={60}>
      {images.length > 0 ? images.map((image, index) => {
        return (
          <div className="imageWrapper" key={index}>
            <img src={image} alt="" />
          </div>
        );
      }): null}
    </Marquee>
  );
}
export default SponsorSlider;