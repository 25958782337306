export const initalValues = {
    category: {
        value: "",
        error: false,
        errorMessage: "",
        required: true,
        touched: false,
    },
    certificate_id: {
        value: null,
        error: false,
        errorMessage: "",
        required: true,
        touched: false,
    },
    emails: {
        value: [],
        error: false,
        errorMessage: "",
        touched: false,
    }
};
