export const initalValues = {
  designation: {
    value: "",
    error: false,
    errorMessage: "",
    required: true,
    touched: false,
  },
  priority: {
    value: 1,
    error: false,
    errorMessage: "",
    required: true,
    touched: false,
  },
};
